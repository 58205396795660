import React from 'react';

const NoMatch = () => {
    return (
        <div>
            NO MATCH
        </div>
    );
};

export default NoMatch;