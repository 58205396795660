const currentCollections = [
  "watercolor",
  "pastels",
  "acrylics",
  "portraits",
  "cards",
  "birds-of-prey",
  "cats"
];

export default currentCollections;
